(function () {
    angular.module('informaApp')
        .service('ExportTrendsViewService', ExportTrendsViewService);


    function ExportTrendsViewService($http, ConstantsSvc, FileDownloader, OmnitureSvc) {
        function exportFile(path, params, filename) {
            var options = {
                url: ConstantsSvc.API.URL + path,
                method: 'POST',
                data: params
            };

            OmnitureSvc.export.trackChart(filename);
            return FileDownloader.downloadBinaryFile(options, filename, function () {
                console.log(`Excel download complete`);
            });

            // return FileDownloader.downloadBinaryFile(options, filename);
        }

        return {
            exportTrendsView: function (searchData, data, colors, labelsVisible) {
                return exportFile('export/trends-view', {
                    searchData,
                    data,
                    colors,
                    labelsVisible,
                }, 'Trends-View-Export.xlsx');
            },
            exportInvertedTrendsView: function (searchData, data, colors, labelsVisible) {
                return exportFile('export/inverted-trends-view', {
                    searchData,
                    data,
                    colors,
                    labelsVisible,
                }, 'Trends-View-Export.xlsx');
            }
        }
    }
})();